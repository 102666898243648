<template>
  <div class="goods" id="goods" name="goods">
    <MyMenu :menuname="'index'"></MyMenu>
    <!-- 面包屑 -->
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/home">全部商品</el-breadcrumb-item>
        <el-breadcrumb-item>{{this.leftNavList.find(item=>item.primaryClassifyCode == this.primaryClassifyCode).primaryClassifyName}}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="search">搜索</el-breadcrumb-item>
        <el-breadcrumb-item v-if="search">{{ search }}</el-breadcrumb-item>
        <el-button type="text" @click="refresh">清空筛选</el-button>
      </el-breadcrumb>
    </div>
    <!-- 面包屑END -->

<!--     分类标签 -->
<!--    <div class="nav">-->
<!--      <div class="nav-wrap">-->
<!--        <div class="product-left">-->
<!--          <div v-for="(item, index) in leftNavList" :key="index" :class="primaryClassifyCode == item.primaryClassifyCode ? 'left-item active' : 'left-item'" @click="handleClickNav(item.primaryClassifyCode, index)">-->
<!--            <span>{{ item.primaryClassifyName }}</span>-->
<!--            <i class="el-icon-arrow-right"></i>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="product-right">-->
<!--          <div class="product-nav" v-for="(item, index) in categoryList" :key="item.twoClassCode">-->
<!--            <div class="title">{{ item.secondaryClassifyName }}</div>-->
<!--            <div class="item">-->
<!--              <el-radio-group v-model="reqParams['params' + (index + 1)]">-->
<!--                <el-radio border size="medium" label="99999" @click.native.prevent="handleClickCategory('99999', index + 1)">全部</el-radio>-->
<!--                <el-radio border size="medium" v-for="attr in item.tertiaryPropertyList" :key="attr.propertyId" :label="attr.propertyId" @click.native="handleClickCategory(attr.propertyId, index + 1)">{{ attr.tertiaryClassifyName }}</el-radio>-->
<!--              </el-radio-group>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="product-nav">-->
<!--            <div class="title">使用场景</div>-->
<!--            <div class="item">-->
<!--              <el-radio-group v-model="recommendLabel">-->
<!--                <el-radio label="99999" border size="medium" @click.native.prevent="clickSence('99999')">全部</el-radio>-->
<!--                <el-radio v-for="item in homeCategoryList" size="medium" border :key="item.labelName" :label="item.labelName" @click.native.prevent="clickSence(item.labelName)">{{ item.labelName }}</el-radio>-->
<!--              </el-radio-group>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--     分类标签END -->

<!--     新分类-->
    <div class="category">
      <div class="classList">
        <div class="classItem">
          <div class="title">分类:</div>
          <div class="itemList">
            <el-radio class="item" v-model="primaryClassifyCode"  size="medium" v-for="(item, index) in leftNavList" :key="item.primaryClassifyCode" :label="item.primaryClassifyCode" @click.native="handleClickNav(item.primaryClassifyCode, index)">{{ item.primaryClassifyName }}</el-radio>
<!--            <div class="item" v-for="item in newCategoryList" @click="selectCategory(item.primaryClassifyCode)" :key="item.primaryClassifyCode">{{ item.primaryClassifyName }}</div>-->
          </div>
        </div>

        <div class="classItem" v-for="(item, index) in categoryList" :key="item.twoClassCode">
          <div class="title">{{item.secondaryClassifyName}}:</div>
          <div class="itemList">
            <el-radio-group v-model="reqParams['params' + (index + 1)]">
              <el-radio  class="item"  size="medium" label="99999" @click.native.prevent="handleClickCategory('','99999', index + 1)">不限</el-radio>
              <el-radio  class="item" size="medium" v-for="attr in item.tertiaryPropertyList" :key="attr.propertyId" :label="attr.propertyId" @click.native="handleClickCategory('',attr.propertyId, index + 1)">{{ attr.tertiaryClassifyName }}</el-radio>
            </el-radio-group>
<!--            <el-radio class="item" v-model="tertiaryClass"  size="medium" v-for="prop in clss.tertiaryPropertyList"  :key="prop.primaryClassifyCode" :label="prop.primaryClassifyCode" >{{ prop.tertiaryClassifyName }}</el-radio>-->
<!--            <div class="item" v-for="prop in clss.tertiaryPropertyList"  :key="prop.primaryClassifyCode">{{ prop.tertiaryClassifyName }}</div>-->
          </div>
        </div>

        <div class="classItem">
          <div class="title">价格范围:</div>
          <div class="itemList">
            <el-radio-group v-model="priceRange">
              <el-radio class="item" label="99999"  size="medium" @click.native.prevent="clickPrice('99999')">不限</el-radio>
              <el-radio class="item" v-for="item in priceList" size="medium"  :key="item.value" :label="item.value" @click.native.prevent="clickPrice(item.value)">{{ item.label }}</el-radio>
            </el-radio-group>
          </div>
        </div>

        <div class="classItem">
          <div class="title">使用场景:</div>
          <div class="itemList">
            <el-radio-group v-model="recommendLabel">
              <el-radio class="item" label="99999"  size="medium" @click.native.prevent="clickSence('99999')">不限</el-radio>
              <el-radio class="item" v-for="item in homeCategoryList" size="medium"  :key="item.labelName" :label="item.labelName" @click.native.prevent="clickSence(item.labelName)">{{ item.labelName }}</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
    </div>
    <!--排序-->
    <div class="sort">
      <div :class=" priceSort==10?'item active':'item'" @click="clickSort(10)">默认排序</div>
      <div :class=" priceSort==11?'item active':'item'" @click="clickSort(11)">最新</div>
      <div :class=" priceSort==0 || priceSort==1?'item active':'item'" @click="clickSort(priceSort == 0?1:0)">
        <span>价格</span>
        <svg v-if="priceSort == 1" t="1729844510413" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5633" width="200" height="200"><path d="M181.959662 382.740984L488.803577 12.615599a32.251167 32.251167 0 0 1 48.020665 0l305.317835 370.125385a32.149429 32.149429 0 0 1-24.010333 53.616294H205.868256a32.149429 32.149429 0 0 1-23.908594-53.616294z" fill="#BFBFBF" p-id="5634"></path><path d="M181.959662 643.090313L488.803577 1013.317437c12.819076 14.243418 35.20159 14.243418 48.020665 0L842.142077 642.988574a32.149429 32.149429 0 0 0-24.010333-53.514555H205.868256a32.149429 32.149429 0 0 0-23.908594 53.514555z" fill="#0057FF" p-id="5635"></path></svg>
        <svg  v-if="priceSort == 0" t="1729844435151" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5156" width="200" height="200"><path d="M181.959662 382.740984L488.803577 12.615599a32.251167 32.251167 0 0 1 48.020665 0l305.317835 370.125385a32.149429 32.149429 0 0 1-24.010333 53.616294H205.868256a32.149429 32.149429 0 0 1-23.908594-53.616294z" fill="#0052D9" p-id="5157"></path><path d="M181.959662 643.090313L488.803577 1013.317437c12.819076 14.243418 35.20159 14.243418 48.020665 0L842.142077 642.988574a32.149429 32.149429 0 0 0-24.010333-53.514555H205.868256a32.149429 32.149429 0 0 0-23.908594 53.514555z" fill="#bfbfbf" p-id="5158"></path></svg>
        <svg  v-if="priceSort == 11 || priceSort == 10" t="1729844470164" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5315" width="200" height="200"><path d="M181.959662 382.740984L488.803577 12.615599a32.251167 32.251167 0 0 1 48.020665 0l305.317835 370.125385a32.149429 32.149429 0 0 1-24.010333 53.616294H205.868256a32.149429 32.149429 0 0 1-23.908594-53.616294z" fill="#bfbfbf" p-id="5316"></path><path d="M181.959662 643.090313L488.803577 1013.317437c12.819076 14.243418 35.20159 14.243418 48.020665 0L842.142077 642.988574a32.149429 32.149429 0 0 0-24.010333-53.514555H205.868256a32.149429 32.149429 0 0 0-23.908594 53.514555z" fill="#bfbfbf" p-id="5317"></path></svg>
      </div>
    </div>
    <!-- 主要内容区 -->
    <div class="main">
      <div class="list">
        <MyList :list="product" v-if="product.length > 0"></MyList>
        <div v-else class="none-product">
          <svg t="1683795562570" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5839" width="200" height="200">
            <path d="M64 409l227.038-152.906A24 24 0 0 1 304.444 252h417.194a24 24 0 0 1 13.492 4.151L960 409v339c0 13.255-10.745 24-24 24H88c-13.255 0-24-10.745-24-24V409z" fill="#9F9F9F" fill-opacity=".5" p-id="5840"></path>
            <path d="M64 409h283.136c13.255 0 24 10.745 24 24v44.68c0 13.254 10.745 24 24 24h233.136c13.255 0 24-10.746 24-24V433c0-13.255 10.745-24 24-24H960v355a8 8 0 0 1-8 8H72a8 8 0 0 1-8-8V409z" fill="#FFFFFF" fill-opacity=".4" p-id="5841"></path>
          </svg>
          <p>
            抱歉没有找到相关的商品，请看看其他的商品
            <a href="/">返回首页</a>
          </p>
        </div>
      </div>
      <!-- 分页 -->
      <div class="pagination">
        <el-pagination background layout="prev, pager, next" :page-size="pageSize" :current-page="currentPage" :total="total" @current-change="currentChange"></el-pagination>
      </div>
      <!-- 分页END -->
    </div>
    <!-- 主要内容区END -->
  </div>
</template>
<script>
import {commodityClassPropertyList} from '@/api/category/index'
export default {
  name: 'goods',
  data() {
    return {
      priceSort:10,
      leftNavList: [],
      categoryList: [], //分类列表
      homeCategoryList: [], //首页全部分类
      product: [], // 商品列表
      total: 0, // 商品总量
      pageSize: 15, // 每页显示的商品数量
      currentPage: 1, //当前页码
      search: '', // 搜索条件
      goodsTimer: null,
      searchTimer: null,
      reqParams: {},
      recommendLabel: '99999', // 使用场景绑定值
      priceRange:'99999',
      primaryClassifyCode: 0, // 左侧一级分类列表,
      priceList:[
        {
          label:'0-200元',
          value:1
        },
        {
          label:'200-400元',
          value:2
        }, {
          label:'400-600元',
          value:3
        },
        {
          label:'600-800元',
          value:4
        },
        {
          label:'800-1000元',
          value:5
        },
        {
          label:'1000元以上',
          value:6
        },
      ]
    }
  },
  async created() {
    // 获取分类列表
    await this.getCategory()
    this.getscene()
    let { search, recommendLabel ,name,id, index} = this.$route.query
    if(search){
      this.search = search
    }
    if (recommendLabel) {
      this.clickSence(recommendLabel)
    } else if(name) {
      this.handleClickCategory(name,id,index+1)
    } else {
      this.getGoodsData()
    }
  },
  activated() {
    this.backtop()
    if (this.$route.query.search != undefined) {
      this.search = this.$route.query.search
    }
  },
  watch: {
    // 监听搜索条件，响应相应的商品
    search(val) {
      if (val != '') {
        this.getProductBySearch(val)
      }
    },
    // 监听路由变化，更新路由传递了搜索条件
    $route(to, from) {
      let { recommendLabel, search, name,id, index } = to.query
      if (search) {
        this.search = search
      }
      if (to.path == '/goods' && from.path == '/home') {
        if (recommendLabel) {
          this.clickSence(recommendLabel)
        }
        if(name) {
          this.handleClickCategory(name,id,index+1)
        }
      } else if (to.path == '/home') {
        this.currentPage = 1
        for (const key in this.reqParams) {
          this.reqParams[key] = '99999'
        }
        this.primaryClassifyCode = this.leftNavList[0].primaryClassifyCode
        this.recommendLabel = '99999'
        this.search = '' // 搜索条件
        this.getGoodsData()
      }
    }
  },
  methods: {
    // 刷新
    refresh(){
      this.primaryClassifyCode = 0
      this.reqParams = []
      this.recommendLabel =  '99999'
      this.priceRange ='99999'
      this.priceSort = 10
      this.currentPage = 1
      this.getGoodsData()
    },
    // 点击左侧分类
    handleClickNav(primaryClassifyCode, index) {
      this.primaryClassifyCode = primaryClassifyCode
      this.categoryList = this.leftNavList[index].secondaryPropertyList
      this.categoryList.forEach((item, index) => {
        this.reqParams['params' + (index + 1)] = '99999'
      })
      this.currentPage = 1
      this.getGoodsData()
    },
    // 点击右侧分类
    handleClickCategory(name, propertyId, index) {
      this.reqParams = {
        ...this.reqParams,
        ['params' + index]: propertyId
      }
      if(name){
        this.primaryClassifyCode = name
        this.categoryList = this.leftNavList.find(item=>item.primaryClassifyCode == name).secondaryPropertyList
      }
      this.currentPage = 1
      this.getGoodsData()
    },
    clickSence(recommendLabel) {
      if (this.recommendLabel != recommendLabel) {
        this.recommendLabel = recommendLabel
        this.getGoodsData()
      }
    },
    clickPrice(priceRange){
      if (this.priceRange != priceRange) {
        this.priceRange = priceRange
        this.getGoodsData()
      }
    },
    clickSort(value){
      this.priceSort = value
      this.getGoodsData()
    },
    // 返回顶部
    backtop() {
      const timer = setInterval(function() {
        const top = document.documentElement.scrollTop || document.body.scrollTop
        const speed = Math.floor(-top / 5)
        document.documentElement.scrollTop = document.body.scrollTop = top + speed

        if (top === 0) {
          clearInterval(timer)
        }
      }, 20)
    },
    // 页码变化调用currentChange方法
    currentChange(currentPage) {
      this.currentPage = currentPage
      if (this.search != '') {
        this.getProductBySearch()
      } else {
        this.getGoodsData()
      }
      this.backtop()
    },
    // 向后端请求分类列表数据
    async getCategory() {
      let { recommendLabel } = this.$route.query
      if (recommendLabel) {
        this.$axios
          .get('/zuwu-api/official/index?applicationType=3')
          .then(({ data }) => {
            let list = data.data.apiCommodityLabelListVo.commodityLabelList
            this.homeCategoryList = list
            this.clickSence(recommendLabel)
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      }

      await this.$axios
        .get('/zuwu-api/official/commodityPropertyList', {})
        .then(({ data }) => {
          this.leftNavList = data.data.primaryPropertyList
          this.primaryClassifyCode = this.leftNavList[0].primaryClassifyCode
          this.categoryList = this.leftNavList[0].secondaryPropertyList
          this.categoryList.forEach((item, index) => {
            this.reqParams['params' + (index + 1)] = '99999'
          })
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 拿使用场景数据
    getscene() {
      this.$axios
        .get('/zuwu-api/official/index?applicationType=3')
        .then(({ data }) => {
          let list = data.data.apiCommodityLabelListVo.commodityLabelList
          this.homeCategoryList = list
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 向后端请求全部商品或分类商品数据
    getGoodsData() {
      if (this.goodsTimer) {
        clearTimeout(this.goodsTimer)
      }
      this.goodsTimer = setTimeout(() => {
        let tertiaryClassifyCodeList = Object.values(this.reqParams).filter((item) => item !== '99999' && item != null)
        let reqData = {
          primaryClassifyCode: this.primaryClassifyCode,
          query: {
            current: this.currentPage,
            size: this.pageSize
          },
          tertiaryClassifyCodeList,
          recommendLabel: this.recommendLabel == '99999' ? '' : this.recommendLabel,
          priceRange: this.priceRange == '99999' ? '' : this.priceRange,
          priceSort:(this.priceSort == 1 || this.priceSort == 0)?this.priceSort:''

        }
        commodityClassPropertyList(reqData).then(({ data }) => {
              let list = data.data.records
              this.total = data.data.total
              this.product = list
            })
            .catch((err) => {
              return Promise.reject(err)
            })
        // // 如果分类列表为空则请求全部商品数据，否则请求分类商品数据
        // this.$axios
        //   .post('/zuwu-api/official/commodityClassPropertyList', reqData)
        //   .then(({ data }) => {
        //     let list = data.data.records
        //     this.total = data.data.total
        //     this.product = list
        //   })
        //   .catch((err) => {
        //     return Promise.reject(err)
        //   })
      })
    },
    // 通过搜索条件向后端请求商品数据
    getProductBySearch() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(() => {
        this.currentPage = 1
        this.total = 0
        this.$axios
          .get('/zuwu-api/official/page', {
            params: {
              title: this.search,
              current: this.currentPage,
              size: this.pageSize
            }
          })
          .then(({ data }) => {
            let list = data.data.records
            this.total = data.data.total
            this.product = list
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/index.css';
.category{
  box-sizing: border-box;
  max-width: 1430px;
  background:#fff ;
  margin: 0 auto;
  padding: 20px;
  .classList{
    .classItem{
      display: flex;
      align-items: center;
      border-bottom: 1px dashed #eee;
      padding: 20px;
      .title{
        margin-right: 40px;
        width: 70px;
      }
      .itemList{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .item{
          margin-right: 30px;
          cursor: pointer;
        }
      }
    }
  }
}
.sort{
  box-sizing: border-box;
  max-width: 1430px;
  background:#fff ;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  .item{
    cursor: pointer;
    margin-right: 20px;
    font-size: 16px;
    display: flex;
    align-items: center;
    svg{
      width: 17px;
      height: 17px;
    }
  }
  .active{
    color: #1e95d4;
  }
}
.el-radio--medium.is-bordered {
  margin-right: 10px;
}
.goods .nav-wrap {
  margin: 0 auto;
  max-width: 1430px;
  display: flex;
  padding: 0 0 12px;
}
.goods .nav-wrap .product-left {
  padding: 20px 0 20px;
  margin-right: 14px;
  background: #fff;
  width: 234px;
}
.goods .nav-wrap .product-left .left-item {
  font-size: 16px;
  line-height: 24px;
  border-radius: 6px;
  padding: 4px 14px;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.goods .nav-wrap .product-left .left-item i {
  font-size: 18px;
}
.goods .nav-wrap .product-left .left-item:hover {
  background: #409eff;
  color: #fff;
}
.goods .nav-wrap .product-left .active {
  background: #409eff;
  color: #fff;
}
.goods .nav-wrap .product-right {
  width: calc(100% - 248px);
  background: #fff;
  padding-top: 20px;
}
/* .goods .nav-wrap .product-nav {
  display: flex;
  flex-direction: row;
} */
.goods .nav-wrap .product-nav .title {
  width: 80px;
  line-height: 36px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
  vertical-align: top;
}
.goods .nav-wrap .product-nav .item {
  width: calc(100% - 80px);
  display: inline-block;
}
.goods .nav-wrap .product-nav .el-radio {
  margin-bottom: 12px;
  margin-left: 0px;
}
</style>
